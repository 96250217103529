<template>
  <div class="mx-3 mt-n2">
    <h2 class="mb-5 txt-heading">Client Forms</h2>
    <template v-if="forms.results.length == 0">
      <div class="text-center">
        <div class="mb-3">
          <img src="../../assets/images/icon-not-found.png" height="50" />
        </div>
        Client form you were searching was not found.
      </div>
    </template>
    <template v-else>
      <v-list-item v-for="(item, index) in forms.results" :key="`${item.title}-${index}`" class="custom-list">
        <v-list-item-content>
          <v-list-item-title v-text="item.title" />
        </v-list-item-content>
        <v-list-item-action>
          <v-menu offset-y nudge-bottom="10px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="blue lighten-3">mdi-eye</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="lang in item.files" :key="lang.language" @click="viewDocument(lang, item.title)">
                <v-list-item-icon style="margin-right: 10px;">
                  <img height="24" :src="require(`../../assets/images/locales/${langIcon(lang.language)}.png`)" />
                </v-list-item-icon>
                <v-list-item-title>
                  {{ langCode[lang.language] }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
      <v-pagination v-model="page" :length="tableLength" :total-visible="7" />
    </template>
    <DocumentViewer :dialog="dialogViewDocument" :item.sync="selectedItem" :title="selectedItem.title" @close="closeDialog" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LANG_CODE as langCode } from '@/constant/language.constant';
import DocumentViewer from '@/components/dialog/DocumentViewer';

export default {
  name: 'ClientResources',
  components: { DocumentViewer },
  computed: {
    ...mapGetters({
      forms: 'HelpCenter/forms',
      params: 'HelpCenter/formParams',
    }),
    tableLength() {
      return Math.ceil(this.forms.count / this.params.limit);
    },
  },
  data: () => ({
    langCode,
    page: 1,
    dialogViewDocument: false,
    selectedItem: {},
  }),
  watch: {
    page(val) {
      const offset = (val - 1) * this.params.limit;
      this.$store.commit('HelpCenter/SET_FORM_PARAMS', { offset });
    },
    params: {
      deep: true,
      handler() {
        this.getForms();
      },
    },
  },
  methods: {
    getForms() {
      this.$store.dispatch('HelpCenter/fetchForms');
    },
    viewDocument(item, title) {
      this.dialogViewDocument = true;
      this.selectedItem = { ...item, title };
    },
    closeDialog() {
      this.dialogViewDocument = false;
      this.selectedItem = {};
    },
    langIcon(lang) {
      const available = ['en', 'zh', 'zh-hant', 'zh-hans', 'jp', 'kr'];
      if (available.includes(lang)) {
        return lang;
      } else {
        return 'default';
      }
    },
  },
  created() {
    this.getForms();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .custom-list {
  background-color: rgba(223, 236, 246, 0.4);
  border-radius: 8px;
  margin-bottom: 10px;

  .v-list-item__title {
    color: rgba(22, 50, 92, 0.8) !important;
  }

  .v-list-item__icon {
    margin-right: 10px;
  }
}
</style>
