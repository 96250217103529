<template>
  <v-row no-gutters>
    <v-col cols="12" md="4">
      <v-list flat nav dense class="list-side pa-6 mb-5 mb-md-0">
        <v-list-item-group v-model="topicSelected" color="primary" @change="topicChange">
          <template class="pa-0" v-if="topicsLoading">
            <v-skeleton-loader type="heading" v-for="index in 5" :key="index" height="45" width="640" />
          </template>
          <template v-else>
            <v-list-item
              v-for="item in topicsData"
              :key="item.id"
              :value="item.id"
              active-class="list-active"
              class="my-2 list-content"
            >
              <v-list-item-content>
                <v-list-item-title v-text="item.name" class="text-left list-text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-col>

    <v-col cols="12" md="8">
      <div class="px-md-5">
        <template v-if="topicContextsLoading">
          <v-skeleton-loader type="article" v-for="index in 3" :key="index" />
        </template>
        <template v-else-if="!topicContextsLoading && topicContextsData.length == 0 && topicsData.length">
          <div class="d-flex align-center justify-center flex-column h-100">
            <SvgIcon icon="icon-no-category" width="300" height="200" class="mb-8" />
            <h2 class="mb-2">
              {{ !topicSelected ? $t('content.no_topic_selected') : $t('content.no_category') }}
            </h2>
            <div>
              {{ !topicSelected ? $t('content.select_topic') : $t('content.no_category_content') }}
            </div>
          </div>
        </template>
        <v-expansion-panels
          multiple
          flat
          v-else-if="!topicContextsLoading && topicContextsData.length"
          v-model="topicContextsExpansion"
        >
          <v-expansion-panel v-for="item in topicContextsData" :key="item.id" @change="contextChange(item.id)">
            <v-expansion-panel-header class="header-accordion px-2" expand-icon="">
              <template #default="{ open }">
                <div class="d-flex align-center justify-start title-accordion">
                  <v-icon color="indigo accent-1" class="mr-5" size="8">
                    mdi-checkbox-blank-circle
                  </v-icon>
                  {{ item.title }}
                </div>
                <v-icon color="primary" size="19" class="justify-end">
                  {{ open ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-left content-accordion">
              <div class=" pt-5 html-viewer">
                <v-skeleton-loader type="paragraph" v-if="!contextBodybyId(item.id)" />
                <div v-else v-html="contextBodybyId(item.id)"></div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SelfHelp',
  data() {
    return {
      topicsLoading: false,
      topicSelected: null,
      topicContextsLoading: false,
      topicContextsExpansion: [],
      contentId: null,
    };
  },
  computed: {
    ...mapGetters({
      topics: 'HelpCenter/topics',
      topicsSearch: 'HelpCenter/topicsSearch',
      topicContexts: 'HelpCenter/topicContexts',
      contextBody: 'HelpCenter/contextBody',
      selectedContext: 'HelpCenter/selectedContext',
    }),
    topicsData() {
      return this.topics;
    },
    topicContextsData() {
      return this.topicContexts({ id: this.topicSelected });
    },
  },
  methods: {
    contextBodybyId(context_id) {
      const context = this.contextBody({ context_id });
      return context?.context_body ?? null;
    },
    contextChange(context_id) {
      const context = this.contextBody({ context_id });
      if (!context) {
        this.$store.dispatch('HelpCenter/getContextBody', {
          context_id,
        });
      }
    },
    fetchTopics() {
      this.$store.dispatch('HelpCenter/getTopics').finally(() => {
        if (!this.topicSelected && !this.$route.query.topic_id) {
          this.topicSelected = this.topics[0]?.id ?? null;
        } else if (this.$route.query.topic_id && this.$route.query.context_id) {
          this.contextChange(this.$route.query.context_id);
        }
        this.topicsLoading = false;
      });
    },
    checkSelectedContext(topic_id) {
      setTimeout(() => {
        if (this.$route.query.topic_id && this.$route.query.context_id) {
          this.topicContextsExpansion = [];
          const idx = this.topicContexts({
            id: topic_id,
          }).findIndex(x => x.id == this.$route.query.context_id);
          if (idx != -1) {
            this.topicContextsExpansion.push(idx);
          }
        }
      }, 500);
    },
    topicChange() {
      this.$router.replace({ query: { tab: 'self-help' } }).catch(() => {});
      this.topicContextsExpansion = [];
    },
  },
  watch: {
    topicSelected(val) {
      if (val) {
        if (!this.$route.query.topic_id && !this.$route.query.context_id) {
          this.topicContextsExpansion = [];
        }
        const context = this.topicContexts({ id: this.topicSelected });
        if (context.length === 0) {
          this.topicContextsLoading = true;
          this.$store.dispatch('HelpCenter/getTopicContexts', val).finally(() => {
            this.topicContextsLoading = false;
            this.checkSelectedContext(val);
          });
        } else {
          this.checkSelectedContext(val);
        }
      }
    },
    '$route.query': {
      immediate: true,
      deep: true,
      handler(qry) {
        if (qry.context_id && qry.topic_id) {
          this.contextChange(qry.context_id);
          if (this.topicSelected != qry.topic_id) {
            this.topicSelected = parseInt(qry.topic_id);
          } else {
            this.checkSelectedContext(parseInt(qry.topic_id));
          }
        } else {
          this.fetchTopics();
        }
      },
    },
  },
  created() {
    this.fetchTopics();
  },
};
</script>

<style lang="scss" scoped>
.list-side {
  background-color: #eff6fb;
  border-radius: 8px;

  .list-active {
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
    border-bottom: none !important;

    .list-text {
      color: #16325c !important;
      font-weight: 900;
    }
  }

  .list-content {
    border-bottom: 1px solid #fff;

    &:last-child {
      border-bottom: none !important;
    }
  }

  .list-text {
    font-size: 16px;
    color: #7d8ca3;
    padding: 10px 8px 8px 13px;
  }
}

.header-accordion {
  border-bottom: 1px solid #efeee6;
}

.title-accordion {
  font-size: 16px;
  font-weight: bold;
  color: #24416d;
}

.content-accordion {
  font-size: 13px;
  color: #6d798b;
}
</style>
