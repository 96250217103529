<template>
  <div>
    <div class="header-table">
      <div class="wrapper">
        <v-col md='2'>
          <div class='title'>Language</div>
          <v-select
            outlined
            dense
            v-model="selectedLanguage"
            :items="languages"
            :item-text="item => item.displayLanguage"
            :item-value="item => item.lang"
          />
        </v-col>
      </div>
    </div>

    <div v-for='(video, index) in videos' :key='index' class='video-wrapper'>
      <h1 style='text-align:center;margin:30px 0px 20px 0px;'>{{ video.title }}</h1>
      <video width="640" height="400" controls="controls" preload="none" :id='"video"+index'>
      </video>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TrainingVideos',
  data() {
    return {
      selectedLanguage:"en",
      languageMapper:{
        en:"English",
        "zh-hans":"简体中文",
        "zh-hant":"繁體中文",
        vi:"Tiếng Việt",
        tr:"Türk",
        de:"Deutsche",
        ja:"日本語",
        ru:"русский",
        uk:"Український",
        es:"Español",
        All:"All"
      }
    };
  },
  computed:{
      ...mapGetters({
        selfHelpVideos: "HelpCenter/selfHelpVideos"
      }),
      videos(){
        if(this.selfHelpVideos==null) return;
        var selected=this.selectedLanguage
        return this.selfHelpVideos.results.filter(function(element){
          return element.language==selected || selected=="All"
        })
      },
      languages(){
        if(this.selfHelpVideos==null) return;
        var allLanguages=[...new Set(this.selfHelpVideos.results.map(item => item.language)), "All"]
        var mapper = this.languageMapper
        var languageObjects = allLanguages.map(function(lang){
          return {
            lang: lang,
            displayLanguage:mapper[lang]
          }
        })
        console.log(languageObjects);
        return languageObjects;
      }
  },
  methods:{
    createVideoSources(){
      if(this.selfHelpVideos==null) return;
      console.log(this.selfHelpVideos)
      for(var a=0;a<this.selfHelpVideos.results.length;a++){
        var video=document.getElementById("video"+a);
        var source = document.createElement('source');
        source.setAttribute('src', this.selfHelpVideos.results[a].videofile);
        source.setAttribute('type', 'video/mp4');
        video.appendChild(source);
      }
    },
  },
  created(){
    this.selectedLanguage=this.$i18n.locale
    this.$store.dispatch('HelpCenter/getVideos').then(() => {
      this.createVideoSources()
    })
  }
};
</script>

<style lang="scss" scoped>
.list-side {
  background-color: #eff6fb;
  border-radius: 8px;

  .list-active {
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
    border-bottom: none !important;

    .list-text {
      color: #16325c !important;
      font-weight: 900;
    }
  }

  .list-content {
    border-bottom: 1px solid #fff;

    &:last-child {
      border-bottom: none !important;
    }
  }

  .list-text {
    font-size: 16px;
    color: #7d8ca3;
    padding: 10px 8px 8px 13px;
  }
}

.header-accordion {
  border-bottom: 1px solid #efeee6;
}

.title-accordion {
  font-size: 13px;
  color: #24416d;
}

.header-table{
  width:100%;
  border-radius:10px;
  text-align:left;
  background-color: rgba(223, 236, 246, 0.9);
  color: rgba(22, 50, 92, 0.8);

  .title{
    font-size:15px !important;
  }
  .wrapper{
    padding:15px;
    padding-bottom:0px;
  }
}

.content-accordion {
  font-size: 13px;
  color: #6d798b;
}
.video-wrapper {
  float:left;
  padding:4%;
}
video{
  margin:0px auto;
  display:block;
}
@media (max-width:1700px){
  .video-wrapper {
    margin:0px auto;
    width:100%;
  }
}
</style>
