<template>
  <div>
    <v-row align="center" class="mb-6" no-gutters>
      <v-col cols="12" md="6" order-md="2">
        <div class="d-flex align-center justify-end mb-5 mb-md-0">
          <template v-if="tab === 0">
            <v-autocomplete
              v-model="select"
              :loading="searchLoading"
              :items="searchResults"
              :search-input.sync="search"
              return-object
              item-text="title"
              item-value="id"
              class="rounded-lg txt-box"
              prepend-inner-icon="mdi-magnify"
              append-icon=""
              flat
              solo
              hide-no-data
              hide-details
              clearable
              no-filter
              placeholder="Ask a question..."
              @click:clear="reset"
            >
              <template #item="{ item }">
                <v-list-item-content class="item-select">
                  <v-list-item-title class="item-select-content">
                    <b>{{ item.title }}</b>
                  </v-list-item-title>
                  <v-list-item-subtitle v-text="item.short_body" />
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </template>
          <template v-else-if="tab === 1">
            <v-text-field
              class="rounded-lg txt-box"
              flat
              solo
              placeholder="Search form..."
              prepend-inner-icon="mdi-magnify"
              clearable
              hide-details
              v-model="searchForm"
            />
          </template>
        </div>
      </v-col>
      <v-col cols="12" md="6" order-md="1">
        <v-tabs
          height="35px"
          v-model="tab"
          @change="tabsChange"
          :grow="$vuetify.breakpoint.smAndDown"
          :show-arrows="$vuetify.breakpoint.smAndDown"
        >
          <v-tab class="tabs" v-for="tabs in tabsItems" :key="tabs">
            {{ tabs }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <SelfHelp />
      </v-tab-item>
      <v-tab-item>
        <ClientResources />
      </v-tab-item>
      <v-tab-item>
        <TrainingVideos />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import SelfHelp from '@/components/help-center/SelfHelp';
import ClientResources from '@/components/help-center/ClientResources';
import TrainingVideos from '@/components/help-center/TrainingVideos';

export default {
  name: 'HelpCenter',
  components: {
    SelfHelp,
    ClientResources,
    TrainingVideos,
  },
  data() {
    return {
      search: null,
      searchForm: null,
      select: null,
      searchLoading: false,
      tab: null,
      tabsItems: ['Self Help', 'Client Resources', 'Videos'],
    };
  },
  computed: {
    ...mapGetters({
      searchResults: 'HelpCenter/searchResults',
    }),
  },
  watch: {
    search: debounce(function(val) {
      if (val) {
        this.searchLoading = true;
        this.$store.dispatch('HelpCenter/search', { search: val }).finally(() => (this.searchLoading = false));
      } else {
        this.$store.commit('HelpCenter/RESET_SEARCH_RESULTS');
      }
    }, 300),
    select(val) {
      if (val) {
        const context_id = val.id;
        const topic_id = val.topic;
        this.$router
          .replace({
            name: `HelpCenter`,
            query: { tab: 'self-help', topic_id, context_id },
          })
          .catch(() => {});
      }
    },
    searchForm: debounce(function(val) {
      this.$store.commit('HelpCenter/SET_FORM_PARAMS', { search: val });
    }, 300),
    '$route.query': {
      immediate: true,
      deep: true,
      handler(qry) {
        if (qry.tab && qry.tab === 'client-resources') this.tab = 1;
      },
    },
  },
  methods: {
    reset() {
      this.$store.commit('HelpCenter/RESET_TOPICS_SEARCH');
      this.$store.commit('HelpCenter/SET_TOPIC_CONTEXTS_SEARCH');
      this.$router
        .replace({
          query: { tab: 'self-help' },
        })
        .catch(() => {});
    },
    tabsChange(val) {
      var changingVal = "self-help";
      if(val>0){
        changingVal=(val==1)?'client-resources':'training-videos';
      }
      if ((!this.$route.query.context_id && !this.$route.query.topic_id) || val === 1) {
        this.$router
          .replace({
            query: { tab: changingVal },
          })
          .catch(() => {});
      }
    },
  },
  destroyed() {
    this.$store.commit('HelpCenter/RESET_TOPICS');
    this.$store.commit('HelpCenter/RESET_TOPIC_CONTEXTS');
    this.$store.commit('HelpCenter/RESET_TOPICS_SEARCH');
    this.$store.commit('HelpCenter/RESET_TOPIC_CONTEXTS_SEARCH');
    this.$store.commit('HelpCenter/RESET_SELECTED_CONTEXTS');
    this.$store.commit('HelpCenter/RESET_CONTEXT_BODY');
    this.$store.commit('HelpCenter/RESET_SEARCH_RESULTS');
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  font-family: ArialMT;
  font-size: 15px;
  font-weight: bold;
  padding: 0 30px 0 33px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  overflow: hidden;
}

.txt-box {
  max-width: 600px;
}

.item-select {
  max-width: 500px;
  position: relative;

  .item-select-content {
    width: 100%;
  }
}

::v-deep .v-input > .v-input__control > .v-input__slot {
  background-color: #ffffff;
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07) !important;
  border-radius: 6px !important;

  .v-input__prepend-inner > .v-input__icon > .v-icon {
    margin-left: 6px;
    margin-right: 12px;
    font-size: 18px;
    color: #dfddce;
  }
}
</style>
